if ($(window).width() < 768) {
  $('.d-flex.flex-column .item').unwrap();
}

$('#propertyGalleryCarousel').owlCarousel({
    margin: 0,
    navText:["<div class='prev-white'></div>","<div class='next-white'></div>"],
    items: 3,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
        singleItem: true,
        dots: true,
      },
      769: {
        items: 3,
      },
    },
  });

  window.addEventListener("resize", function() {
    $('#propertyGalleryCarousel').trigger('refresh.owl.carousel');
  });
  

  $('#propertyReviewCarousel').owlCarousel({
    margin: 0,
    dots: true,
    nav: false,
    items: 1,
    singleItem: true,
    autoplay: true,
    autoplayTimeout: 4000,
    loop: true
  });

  $('#propertyAmenitiesCarousel').owlCarousel({
    margin: 0,
    items: 1,
    nav: false,
    dots: true,
    singleItem: true,
    loop: true,
  });

  if ($('#openForm').length > 0) {
    $('#openForm')[0].addEventListener('click', function() {
      $('#propertyInquiryForm').addClass('opened');
      $('.greyout-bg').addClass('active');

    });
  }

  if ($('.close-btn').length > 0) {
    $('.close-btn')[0].addEventListener('click', function() {
      $('#propertyInquiryForm').removeClass('opened');
      $('.greyout-bg').removeClass('active');
    });
  }