var list_carousels = $('[id^="list-carousel-"]');
list_carousels.each(function () {
    $(this).owlCarousel({
        navText: ["<div class='thumbnail-prev'></div>", "<div class='thumbnail-next'></div>"],
        loop: true,
        nav: true,
        // center: true,
        responsive: {
            0: {
                margin: 10,
                items: 3,
                dots: false,
            },

            500: {
                margin: 10,
                items: 1,
                singleItem: true,
                dots: true,
            }
        }

    });

});


if ($('.icon-grid-view').length > 0) {
    $('.icon-grid-view')[0].addEventListener('click', function () {
        $('.icon-map-view').removeClass('active');
        $('#viewMode').removeClass('map-view').addClass('grid-view')
        this.classList.add('active');
        list_carousels.each(function () {
            $(this).trigger('refresh.owl.carousel')
        });
    });
}

if ($('.icon-map-view').length > 0) {
    $('.icon-map-view')[0].addEventListener('click', function () {
        $('.icon-grid-view').removeClass('active');
        $('#viewMode').removeClass('grid-view').addClass('map-view')
        this.classList.add('active');
        list_carousels.each(function () {
            $(this).trigger('refresh.owl.carousel')
        });
    });
}

if ($('.find__filter__show').length > 0) {
    $('.find__filter__show')[0].addEventListener('click', function () {
        $('.find__filter__more').addClass('active');
    });
}

if ($('.find__filter__hide').length > 0) {
    $('.find__filter__hide')[0].addEventListener('click', function () {
        $('.find__filter__more').removeClass('active');
    });
}
var initialValue = 120, min = 100, max = 500;

$('#budgetSlider').slider({
    value: initialValue,
    min: min,
    max: max,
    step: 10,
    slide: function (event, ui) {
        var delay = function () {
            var handleIndex = $(ui.handle).data('index.uiSliderHandle');
            var label = handleIndex == 0 ? '#min' : '#max';
            $("#budgetSliderLabel").html('$' + ui.value + '/night').position({
                my: 'center top',
                at: 'center top',
                of: ui.handle,
                offset: "5, -30"
            });
        };
        setTimeout(delay, 5);
    }
})

$('#budgetSliderLabel').html('$' + initialValue + '/night').position({
    my: 'center top',
    at: 'center top',
    offset: "5, -30"
});

function repaint(data) {
    $(".result-lists.result-lists--best").html('');
    $(".result-lists.result-lists--rest").html('');
    $(".result-lists.result-lists--fav").html('');

    for (var i = 0; i < data.length; i++) {
        $('#resultTemplate .owl-carousel').html('');
        for (let j = 0; j < data[i].img.length; j++) {
            if (data[i].img.length > 5) data[i].img.length = 5;
            var div = document.createElement("div");
            $(div).addClass("item");
            if ($('#resultTemplate').length > 0) {
                $('#resultTemplate .result-lists__each')[0].id = data[i].id;
                $('#resultTemplate .owl-carousel')[0].id = 'carousel' + i;
                $('#resultTemplate .owl-carousel')[0].prepend(div);
    
                var img = document.createElement("img");
                img.src = "/assets/images/" + data[i].img[j];
                $('#resultTemplate #carousel' + i + ' .item')[0].appendChild(img);
            }
        }

        $("#resultTemplate .address").html(data[i].address);
        $("#resultTemplate .city").html(data[i].city);
        $("#resultTemplate .price").html(data[i].price1);
        $("#resultTemplate .star").html(data[i].star);
        $("#resultTemplate .units").html(data[i].units);

        if ($('.result-lists--fav').length > 0) {
            $(".result-lists.result-lists--fav").append($("#resultTemplate").html());
        }
        else {
            if (data[i].best == true) {
                $(".result-lists.result-lists--best").append($("#resultTemplate").html());
            } else {
                $(".result-lists.result-lists--rest").append($("#resultTemplate").html());
            }  
        }

        if ($('#resultTemplate').length > 0) {
            $('#resultTemplate .result-lists__each')[0].id = '';
        }
    }

    var ls_fav_list = JSON.parse(localStorage.getItem("ls_fav_list"));
    if ($('.result-lists--fav').length > 0) {
        $('.result-lists__each').hide();
        if(ls_fav_list != null) {
            for(let i=0; i<ls_fav_list.length; i++) {
                $("#" + ls_fav_list[i]).show();
                $("#" + ls_fav_list[i] + " .fav").addClass('active');
            }
        }
    }
    else {
        if(ls_fav_list != null) {
            for(let i=0; i<ls_fav_list.length; i++) {
                $("#" + ls_fav_list[i] + " .fav").addClass('active');
            }
        }
    }
    //for carousel on newly created lists
    var findBest_carousels = $('[id^="carousel"]');
    findBest_carousels.each(function () {
        $(this).owlCarousel({
            navText: ["<div class='thumbnail-prev'></div>", "<div class='thumbnail-next'></div>"],
            loop: true,
            nav: true,
            responsive: {
                0: {
                    margin: 10,
                    items: 3,
                    dots: false,
                },

                500: {
                    margin: 10,
                    items: 1,
                    singleItem: true,
                    dots: true,
                }
            }

        });

    });
}

function fetchResults() {
    $.ajax({
        url: '/assets/data/properties.json', // TODO: Connect an endpoint to fetch property data
        type: 'post',
        dataType: 'json',
        success: function (rslt) {
            repaint(rslt);
        }
    });
}

$("#findFilterForm").submit(function (event) {
    event.preventDefault();
    fetchResults();
    return false;
});

$(document).ready(function() {
    fetchResults();
});
