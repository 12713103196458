$('#processCarousel').owlCarousel({
    margin: 0,
    navText:["<div class='prev-mobile'></div>","<div class='next-mobile'></div>"],
    items: 5,
    dots: false,
    responsive: {
      0: {
        items: 1,
        dots: true,
      },
      500: {
        items: 2,
      },
      767: {
        items: 3,
      },
      1100: {
        items: 4,
      },
    },
  });

  $('#matchCarousel').owlCarousel({
    margin: 0,
    navText:["<div class='slider-prev'>Previous</div>","<div class='slider-next'>Next</div>"],
    dots: false,
    items: 1,
    singleItem: true,
    mouseDrag: false,
    touchDrag: false,
  });

  var sliders = $('[id^="slider"]');
  sliders.each(function () {
    $(this).slider({
      value: 5.5,
      min: 1,
      max: 10,
      step: 0.5,
    })

    .each(function() {
     // Get the options for this slider      
      var opt = $(this).data().uiSlider.options;
      var vals = opt.max - opt.min;

     // Space out values      
      for (var i = 0; i <= vals; i++) {
        var el = $('<label>'+(i+opt.min)+'</label>').css('left',(i/vals*100)+'%');
        $(this).append(el);
      }
    });
  });

  $('#citiesCarousel').owlCarousel({
    margin: 0,
    navText:["<div class='prev-grey'></div>","<div class='next-grey'></div>"],
    items: 7,
    dots: false,
    nav: true,
    responsive: {
      0: {
        items: 4,
      },

      1100: {
        items: 7,
      },
    },
  });

  $('#citiesMobileCarousel').owlCarousel({
    margin: 0,
    navText:["<div class='prev-mobile'></div>","<div class='next-mobile'></div>"],    
    items: 1,
    dots: false,
    nav: true,
    singleItem: true,
  });

  $('#reviewCarousel').owlCarousel({
    margin: 0,
    dots: true,
    nav: false,
    items: 1,
    singleItem: true,
    autoplay: true,
    autoplayTimeout: 4000,
    loop: true
  });

  function populateCity() {
    var cityList = $('#citiesCarousel .item');
    if (cityList.length > 0) {
        for (i = 0; i < cityList.length; i++) {
            (function(i){
                cityList[i].addEventListener('click', function () {
                    window.location.href = '/city#' + this.id;
                });
            })(i);
        }
    }
  }
  
  populateCity();

  function populateCityMobile() {
    var cityList = $('#citiesMobileCarousel .item__wrapper');
    if (cityList.length > 0) {
        for (i = 0; i < cityList.length; i++) {
            (function(i){
                cityList[i].addEventListener('click', function () {
                    window.location.href = '/city#' + this.id;
                });
            })(i);
        }
    }
  }

  populateCityMobile()