window.addEventListener("DOMContentLoaded", function () {
    $("#header").load("../partials/header.html");
    $("#footer").load("../partials/footer.html");
    if (("#find-form").length > 0) {
        $("#find-form").load("../partials/find-form.html");
    }
    if (("#questions").length > 0) {
        $("#questions").load("../partials/questions.html");
    }
});

$(window).load(function () {
    setTimeout(function(){ 
        $('nav a').each(function () {
            if ($(this).attr('href') == window.location.pathname) {
                $(this).addClass('active');
            }
        });
        
        var lang = document.getElementsByTagName("html")[0].getAttribute("lang");
    
        if (lang == 'en') {
            $('.header__nav__lang a:last-child').removeClass('active');
            $('.header__nav__lang a:first-child').addClass('active');
        }
        else {
            $('.header__nav__lang a:first-child').removeClass('active');
            $('.header__nav__lang a:last-child').addClass('active');
        }
     }, 300);   

    var fav = $('.fav');
    var fav_list = [];
    if (fav.length > 0) {
        for (let i = 0; i < fav.length; i++) {
            fav[i].addEventListener('click', function () {

                fav_list = JSON.parse(localStorage.getItem("ls_fav_list"));

                if (fav_list == null) {
                    $(this).addClass('active');
                    fav_list = [$(".result-lists__each")[i].id];
                }
                else if(fav_list.indexOf($(".result-lists__each")[i].id) == -1) {
                    fav_list.push($(".result-lists__each")[i].id);
                        $(this).addClass('active');
                }
                else {
                    var index = fav_list.indexOf($(".result-lists__each")[i].id);
                    fav_list.splice(index, 1);
                        $(this).removeClass('active');
                }

                localStorage.setItem("ls_fav_list", JSON.stringify(fav_list));
            });
        }
    }


    var dropdown = $('li.dropdown');
    var allContent = $('.dropdown-content');

    if (dropdown.length > 0) {
        for (i = 0; i < dropdown.length; i++) {
            dropdown[i].addEventListener('click', function () {
                this.classList.toggle('clicked');
                var content = $(this).children('.dropdown-content');
                if (content.hasClass('show')) {
                    content.removeClass('show');
                } else {
                    allContent.removeClass('show');
                    content.addClass('show');
                }
            });
        }
    }

    if ($('.hamburger-menu').length > 0) {
        $('.hamburger-menu')[0].addEventListener('click', toggleNav);
    }

    function toggleNav() {
        $('.hamburger-menu').toggleClass('change');
        $('.nav__bottom').toggleClass('show');
    }

    // accordion


    var acc = $('div.accordion');

    for (var i = 0; i < acc.length; i++) {
        acc[i].addEventListener('click', function () {

            // $('div.panel').removeClass('show');

            var panel = this.nextElementSibling;
            if (this.classList.contains('active')) {
                this.classList.remove('active');
                panel.classList.remove('show');

            } else {
                // acc.removeClass('active');
                this.classList.add('active');
                panel.classList.add('show');
            }
        });
    }


    var close = $('.panel-close');

    for (i = 0; i < close.length; i++) {
        close[i].addEventListener('click', function () {
            $('.panel').removeClass('show');
            $('.accordion').removeClass('active');
        });
    }

    function updateDate() {
        var firstdate = document.getElementById("checkIn").value;
        document.getElementById("checkOut").value = "";
        document.getElementById("checkOut").setAttribute("min", firstdate);
    }

    $("#checkIn").on("change", updateDate);

    $('#contactForm').on("submit", function() {
        alert("Thank you for your submission");
    });
    $('#inquiryForm').on("submit", function() {
        alert("Thank you for your submission");
    })
});
