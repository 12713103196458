$('.awards-carousel').owlCarousel({
    
            items: 1,
            loop: true,
            nav: true,
            dots:true,
            margin:10,
            responsive : {
                0 : {
                },
                600 : {
                    items: 3,
                },
                991 : {
                    items: 4,
                },
                1200 : {
                    items: 5,
                },
                1500 : {
                    items: 6,
                    
                }
            }

});